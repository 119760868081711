import firebase from "firebase/app";
import "firebase/auth";

export const auth = firebase.initializeApp({
    apiKey: "AIzaSyCmaJSkwMxgmNwsdpMG_9gO_362G7YYGbA",
    authDomain: "ichat-88ea0.firebaseapp.com",
    projectId: "ichat-88ea0",
    storageBucket: "ichat-88ea0.appspot.com",
    messagingSenderId: "552770598560",
    appId: "1:552770598560:web:e504a7ba0f13b6ca4abbc5",
    measurementId: "G-373438Z7Z9"
  }).auth();